<template>
  <div class="example-3d">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in imgArr" :key="index">
        <img
            :src="item.src"
            alt=""
        />
        <p class="diaopoit" v-if="showFlag"></p>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev" @click="prevBtn"></div>
      <div class="swiper-button-next" slot="button-next" @click="nextBtn"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'swiper-example-3d-coverflow',
  title: '3D Coverflow effect',
  components: {
    Swiper,
    SwiperSlide
  },
  props:['imgList','SlideIndex','prevFlag'],
  data() {
    return {
      imgArr:[
        {
          src:require("../../assets/img/rcfz/rcfzSwiper.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper1.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper2.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper3.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper4.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper5.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper6.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper7.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper8.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper9.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper10.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper11.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper12.png")
        },
      ],
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        initialSlide :4,
        centeredSlides: true,
        slidesPerView: 'auto',
        loop: true,
        coverflowEffect: {
          rotate: 0,
          stretch: -400,
          depth: 100,
          modifier: 1,
          slideShadows : false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      showFlag: false
    }
  },
  created() {
    this.swiperOption.initialSlide = this.$props.SlideIndex;
  },
  mounted() {
    this.imgArr = this.$props.imgList;
  },
  methods: {
    prevBtn() {
      this.$emit('childEvent', this.swiperOption.initialSlide);
      console.log(this.swiperOption.initialSlide);
    },
    nextBtn() {
      this.$emit('childEvent', this.swiperOption.initialSlide);
      console.log(this.swiperOption.initialSlide);
    }
  }
}
</script>

<style lang="scss" scoped>
.example-3d {
  width: 100%;
  height: 552px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 100px;
}

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 952px;
    height: 552px;
    text-align: center;
    font-weight: bold;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .swiper-button-prev {
    left: 12%;
    width: 154px;
    height: 28px;
    background-image: url(./img/staffImg/leftBtn.png);
    background-position: 100%;
    background-repeat: no-repeat;
  }
  .swiper-button-next {
    right: 12%;
    width: 154px;
    height: 28px;
    background-image: url(./img/staffImg/rightBtn.png);
    background-position: 100%;
    background-repeat: no-repeat;
  }
  .swiper-button-next:after{
    content: none;
  }
  .swiper-button-prev:after{
    content: none;
  }
}
.diaopoit{
  position: absolute;
  top: 25%;
  left: 45%;
  width: 16px;
  height: 16px;
  border: 3px solid red;
  border-radius: 50%;
  animation: myfirst 3s infinite;
}
@keyframes myfirst {
  /* 设置为百分50可以缩放 */
  50% {
    transform: scale(1.5);
  }
}
</style>