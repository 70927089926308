<template>
<div class="footcon">
  <div class="showhearder" >
          <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
          <div class="heaitem" @click="topdis(0)">
            <img src="../assets/img/nav/homeNav.png" alt="" class="heaitemimg">
            <p class="heaitemtext">首页</p>
          
          </div>
          <ul class="ullist" v-if="topdiscon==0">
              <router-link  to="/index" style="text-decoration: none; color: black;">
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'index' }">首页</li>
              </router-link>
<!--              <router-link  to="/subordinate" style="text-decoration: none; color: black;">
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'subordinate' }">集团介绍</li>
              </router-link>-->

<!--              <router-link  to="/motherland" style="text-decoration: none; color: black;">
                <li class="liitem " :class="{ liactive: $store.state.currPage == 'motherland' }">新闻资讯</li>
              </router-link>-->
            
<!--              <router-link  to="/sahnpinye" style="text-decoration: none; color: black;">
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'sahnpinye' }">
                  集团产品</li>
              </router-link>-->
<!--              <router-link  to="/weiyiw" style="text-decoration: none; color: black;">
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'weiyiw' }">
                  尾易</li>
              </router-link>-->
            </ul>
          <div class="heaitem" @click="topdis(1)">
            <img src="../assets/img/nav/homeNav1.png" alt="" class="heaitemimg">
            <p class="heaitemtext">鸿旗集团</p>

          </div>
          <ul class="ullist" v-if="topdiscon==1">
              <router-link  to="/qiyejs" style="text-decoration: none; color: black;"> 
              <li class="liitem " :class="{ liactive: $store.state.currPage == 'qiyejs' }">企业简介</li>     </router-link>
<!--              <router-link  to="/subordinate" style="text-decoration: none; color: black;">   <li class="liitem" :class="{ liactive: $store.state.currPage == 'subordinate' }">下属公司介绍</li>  </router-link>-->
<!--              <router-link  to="/weiyiwang" style="text-decoration: none; color: black;">   <li class="liitem" :class="{ liactive: $store.state.currPage == 'weiyiwang' }">企业文化</li>  </router-link>-->
             
<!--              <router-link  to="/subordinate" style="text-decoration: none; color: black;">
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'subordinate' }">
                  企业发展历程</li>
                  </router-link>-->
<!--              <router-link  to="/supplychain" style="text-decoration: none; color: black;">
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'supplychain' }">
                  营销渠道</li>
                  </router-link>-->
              <router-link  to="/callus" :class="{ liactive: $store.state.currPage == 'callus' }" style="text-decoration: none; color: black;">   <li class="liitem">联系我们</li>    </router-link> 
            </ul>
            <div class="heaitem" @click="topdis(2)">
            <img src="../assets/img/nav/homeNav2.png" alt="" class="heaitemimg">
            <p class="heaitemtext" >让员工幸福</p>

          </div>
          <ul class="ullist" v-if="topdiscon==2">
            <router-link  to="/founderstory" style="text-decoration: none; color: black;">   
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'founderstory' }">
                  创始人故事</li>
                  </router-link>
              <router-link  to="/coremanment" style="text-decoration: none; color: black;">   
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'coremanment' }">
                  核心管理层</li>
                  </router-link>
                  <router-link  to="/mentalres" style="text-decoration: none; color: black;">   <li class="liitem" :class="{ liactive: $store.state.currPage == 'mentalres' }">心力资源</li>  </router-link>
                  <router-link  to="/employeegrowth" style="text-decoration: none; color: black;">   
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'employeegrowth' }">
                  员工成长历程</li>
                  </router-link>
              <router-link  to="/familyhapp" style="text-decoration: none; color: black;">   <li class="liitem" :class="{ liactive: $store.state.currPage == 'familyhapp' }">幸福建设</li>  </router-link>
           
            </ul>
         
             <div class="heaitem" @click="topdis(3)">
            <img src="../assets/img/nav/homeNav3.png" alt="" class="heaitemimg">
            <p class="heaitemtext"  >让客户感动</p>
          </div>
          <ul class="ullist" v-if="topdiscon==3">
            <router-link  to="/sahnpinye" style="text-decoration: none; color: black;">
              <li class="liitem ">产品介绍</li>     </router-link> 
              <router-link  to="/sahnpinye#hzkh" style="text-decoration: none; color: black;">
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'subordinate' }">
                  核心服务商</li>
                  </router-link>
<!--              <router-link  to="/employeegrowth" style="text-decoration: none; color: black;">
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'employeegrowth' }">
                  战略合作伙伴</li>
                  </router-link>-->
              <router-link  to="/sahnpinye" style="text-decoration: none; color: black;">   
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'sahnpinye' }">
                  项目案例</li>
                  </router-link>
              <router-link  to="/hongqipower" style="text-decoration: none; color: black;">   
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'hongqipower' }">
                  定制化解决方案</li>
                  </router-link>
              <router-link  to="/supplychain" style="text-decoration: none; color: black;">   
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'supplychain' }">
                  供应链服务/鸿旗普惠</li>
                  </router-link>
            </ul>
            <div class="heaitem" @click="topdis(4)">
            <img src="../assets/img/nav/homeNav4.png" alt="" class="heaitemimg">
            <p class="heaitemtext" >让社会尊重</p>
          </div>
          <ul class="ullist" v-if="topdiscon==4">
              <router-link  to="/subordinate" style="text-decoration: none; color: black;">   
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'subordinate' }">
                  社会责任</li>
                  </router-link>
             
           
            </ul>
            <div class="heaitem" @click="topdis(5)">
            <img src="../assets/img/nav/homeNav5.png" alt="" class="heaitemimg">
            <p class="heaitemtext"  >让祖国放心</p>
          </div>
          <ul class="ullist" v-if="topdiscon==5">
            <router-link  to="/motherland" style="text-decoration: none; color: black;">
              <li class="liitem " :class="{ liactive: $store.state.currPage == 'motherland' }">让祖国放心</li>     </router-link>
            <router-link  to="/weiyiw" style="text-decoration: none; color: black;">
              <li class="liitem " :class="{ liactive: $store.state.currPage == 'weiyiw' }">尾易网</li>     </router-link>
<!--              <router-link  to="/subordinate" style="text-decoration: none; color: black;">
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'subordinate' }">
                  鸿旗新材</li>
                  </router-link>-->
                  <router-link  to="/newenergy" style="text-decoration: none; color: black;">   
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'newenergy' }">
                  新能源</li>
                  </router-link>
             
<!--              <router-link  to="/hongqipower" style="text-decoration: none; color: black;">
                <li class="liitem" :class="{ liactive: $store.state.currPage == 'hongqipower' }">
                  制造业赋能</li>
                  </router-link>-->
            </ul>
        </div>
  
  </div>
</template>

<script>

export default {
  data(){
    return{
      topdiscon:null,
      
    }
  },
 
  mounted(){
   
  },
  methods:{
    topdis(a){
      this.topdiscon = a
    },
    showhearder(){
      this.$parent.showhearder()
    }
  
  }
}
</script>

<style scoped lang="scss"> 
 .footcon{
  .showhearder{
  padding: 50px ;
  //padding-top: 100px;
  
  width: 345px;
  height: 850px;
  position: absolute;
 right: 0px;
 top: 0px;
 background-color: white;
 z-index: 10;
 .heaitem{
  // border: 1px solid red;
  display: flex;
  align-items: center;
  margin: 30px 0;
  .heaitemtext{
    // font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
  }
 }
 .ullist{
   padding-left: 80px;
  .liitem{
     list-style: none;
     margin: 20px 0;
  }
  .liactive{
    color: rgb(230,82,86);
  }

 }
 }
   }
</style>
