<template>
  <div class="footerCon">
    <div class="footerBox">
      <div class="listBox">
        <div class="list">
          <p>鸿旗企业</p>
          <router-link  to="/qiyejs" style="text-decoration: none;margin-bottom: 25px;"><span>企业简介</span></router-link>
          <span>子公司介绍</span>
          <span>企业文化</span>
          <span>企业发展历程</span>
          <span>营销渠道</span>
          <router-link  to="/callus" style="text-decoration: none;margin-bottom: 25px;"><span>董事长直达</span></router-link>
        </div>
        <div class="list">
          <p>让员工幸福</p>
          <router-link  to="/founderstory" style="text-decoration: none;margin-bottom: 25px;">
            <span>创始人故事</span>
          </router-link>
          <router-link  to="/coremanment" style="text-decoration: none;margin-bottom: 25px;">
            <span>核心管理层</span>
          </router-link>
          <router-link  to="/mentalres" style="text-decoration: none;margin-bottom: 25px;">
            <span>心力资源</span>
          </router-link>
          <router-link  to="/employeegrowth" style="text-decoration: none;margin-bottom: 25px;">
            <span>员工成长历程</span>
          </router-link>
          <router-link  to="/familyhapp" style="text-decoration: none;margin-bottom: 25px;">
            <span>幸福建设</span>
          </router-link>
        </div>
        <div class="list">
          <p>让客户感动</p>
          <router-link  to="/sahnpinye" style="text-decoration: none;margin-bottom: 25px;">
            <span>产品介绍</span>
          </router-link>
          <router-link  to="/sahnpinye#hzkh" style="text-decoration: none;margin-bottom: 25px;">
            <span>核心服务商</span>
          </router-link>
          <router-link  to="/sahnpinye" style="text-decoration: none;margin-bottom: 25px;">
            <span>项目案例</span>
          </router-link>
          <router-link  to="/hongqipower" style="text-decoration: none;margin-bottom: 25px;">
            <span>定制化解决方案</span>
          </router-link>
          <router-link  to="/supplychain" style="text-decoration: none;margin-bottom: 25px;">
            <span>供应链服务/鸿旗普惠</span>
          </router-link>
        </div>
        <div class="list">
          <p>让社会尊重</p>
          <span>新闻资讯</span>
          <router-link  to="/subordinate" style="text-decoration: none;margin-bottom: 25px;">
            <span>社会责任</span>
          </router-link>
        </div>
        <div class="list">
          <p>让祖国放心</p>
          <router-link  to="/weiyiw" style="text-decoration: none;margin-bottom: 25px;">
            <span>尾易网</span>
          </router-link>
          <span>鸿旗新材</span>
          <router-link  to="/newenergy" style="text-decoration: none;margin-bottom: 25px;">
            <span>新能源</span>
          </router-link>
          <span>制造业赋能</span>
        </div>
      </div>
      <div class="rightBox">
        <p>400-600-8891</p>
        <span>上海市宝山区友谊路1558弄35栋</span>
        <div class="iconBox">
          <img src="./shehui/img/footer/WeChat.png" alt="">
          <img src="./shehui/img/footer/dy.png" alt="">
          <img src="./shehui/img/footer/Email.png" alt="">
        </div>
        <img src="./shehui/img/footer/logo.png" class="logo" alt="">
      </div>
    </div>
    <div class="tips">
      <em></em>
      <p>
        沪ICP备2023006677号-1
      </p>
      <em></em>
    </div>
  </div>

</template>

<script>

export default {
  data(){
    return{
    
      
    }
  },
 
  mounted(){
   
  },
  methods:{
   
  
  }
}
</script>

<style scoped lang="scss">
.footerCon {
  width: 100%;
  height: 700px;
  box-sizing: border-box;
  background: #fafafa;
  padding: 76px 0 0 160px;
  position: relative;
  .footerBox {
    width: 100%;
    height: 100%;
    display: flex;
    .listBox {
      display: flex;
      .list {
        display: flex;
        flex-direction: column;
        margin-right: 80px;
        p {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-bottom: 44px;
        }
        span {
          font-size: 18px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #999999;
          line-height: 25px;
          margin-bottom: 25px;
        }
      }
    }
    .rightBox {
      margin-left: 180px;
      margin-top: 22px;
      p {
        font-size: 24px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #666666;
        line-height: 36px;
        margin-bottom: 45px;
      }
      span {
        font-size: 24px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #666666;
        line-height: 28px;
      }
      .iconBox {
        margin-top: 102px;
        img {
          width: 44px;
          height: 44px;
          margin-right: 58px;
        }
      }
      .logo {
        width: 281px;
        height: 233px;
        margin: 37px 0 0 18px;
      }
    }
  }
  .tips {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 18px;
    font-family: AppleSystemUIFont;
    color: #666666;
    line-height: 27px;
    display: flex;
    align-items: center;
    left: 65%;
    transform: translate(-50%,-50%);
    p {
      font-size: 18px;
      font-family: AppleSystemUIFont;
      color: #666666;
      line-height: 27px;
      margin: 0 33px;
    }
    em {
      display: block;
      width: 518px;
      height: 1px;
      background: #979797;
    }
  }
}
.footcon{
  background-image: url(../assets/img/dibudaoh.png);
  width: 100%;
  height: 700px;
  background-size: 100% 100%;
  position: relative;
  .bah {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    p {
      font-size: 18px;
      font-family: AppleSystemUIFont;
      color: #666666;
      line-height: 27px;
    }
  }
}
//  .footcon{
//   max-width: 1600px;
//   margin: 200px auto;
//   display: flex;
//   justify-content: space-between;
//     .foleft{
//       width: 60%;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
// .foleftitem{
//   .folefttop{
   
//   }
//   .folepay{

//   }
// }
//     }
//     .foright{

//     }
//    }
</style>
